<template>
  <v-container class="table-container">
    <v-row>
      <v-col>
        <sentiment-table
          :is-loading="isLoading"
          :change-item="changeItem"
          :metric-changes="metricChanges"
        >
          <v-btn @click="resetValues" outlined>{{
            $t("COMMON.RESET_ALL_CHANGES")
          }}</v-btn>
        </sentiment-table>
      </v-col>
      <v-col>
        <metrics-table :is-loading="isLoading" :is-changed="isChanged">
          <v-btn @click="recomputeChanges()" :disabled="!isChanged" outlined>{{
            $t("COMMON.RECALCULATE_CHANGES")
          }}</v-btn>
        </metrics-table>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer />
      <v-col class="text-right">
        <v-btn
          class="success--text"
          outlined
          @click="handleNextStep"
          :disabled="isChanged || isLoading"
          >{{ $t("COMMON.NEXT_STEP") }}</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SentimentTable from "@/views/DataPreview/components/SentimentTable";
import MetricsTable from "@/views/DataPreview/components/MetricsTable";
import { mapActions } from "vuex";
export default {
  name: "DataPreviewTables",
  components: { MetricsTable, SentimentTable },
  data() {
    return {
      isChanged: false,
      isLoading: false,
      metricChanges: {}
    };
  },
  async created() {
    await this.resetValues();
  },
  methods: {
    ...mapActions({
      recalculateMetrics: "metrics/recalculateMetrics",
      recalculateAndSaveMetrics: "metrics/recalculateAndSaveMetrics",
      getMetrics: "metrics/getMetrics"
    }),
    resetValues() {
      const { projectId } = this.$route.params;
      this.isLoading = true;
      return this.getMetrics(projectId)
        .catch(error => {
          console.log(error);
          this.$notifier.notify({
            message: `${projectId} is invalid`,
            color: "error"
          });
        })
        .then(() => {
          this.metricChanges = {};
          this.isChanged = false;
          this.isLoading = false;
        });
    },
    recomputeChanges() {
      let changesData = Object.entries(this.metricChanges).map(
        ([metricId, value]) => {
          return {
            id: metricId,
            value: +value.value
          };
        }
      );
      this.isLoading = true;
      const metricsParam = {
        projectId: this.$route.params.projectId,
        data: changesData
      };
      this.recalculateMetrics(metricsParam).finally(() => {
        this.isChanged = false;
        this.isLoading = false;
      });
    },
    recomputeAndSaveChanges() {
      let changesData = Object.entries(this.metricChanges).map(
        ([metricId, value]) => ({
          id: metricId,
          value: +value.value
        })
      );
      const metricsParam = {
        projectId: this.$route.params.projectId,
        data: changesData
      };
      this.recalculateAndSaveMetrics(metricsParam)
        .then(() => {
          this.$router.push({
            name: "Dashboard",
            params: {
              id: this.$route.params.projectId
            }
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    changeItem(value, item) {
      if (value.length !== 0 && value !== item.value) {
        this.metricChanges[item.metricId] = {
          value: +value,
          defaultValue: +item.defaultValue
        };
        this.isChanged = true;
        item.value = +value;
      }
    },
    handleNextStep() {
      this.recomputeAndSaveChanges();
    }
  }
};
</script>
