<template>
  <div class="table-border pa-3">
    <div class="text-h6 title text-left">
      {{ $t("DATA_PREVIEW.TEXT.SENTIMENT.HEADER") }}
    </div>
    <div class="table-description text-left caption font-italic opacity-7">
      <v-icon small> mdi-alert </v-icon>
      {{ $t("DATA_PREVIEW.TEXT.SENTIMENT.DESCRIPTION") }}
    </div>
    <v-data-table
      :headers="sentimentBrandsHeader"
      :items="metricsData"
      :items-per-page="metricsData.length"
      :loading="isLoading"
      class="elevation-0 mt-4"
      disable-sort
      hide-default-footer
    >
      <template v-slot:item.brandName="items">
        <tr class="brands-td">
          <td v-for="brand in items" :key="brand.brandId">
            <div :title="brand.brandName" v-if="brand.brandId">
              {{ brand.brandName }}
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:item.positive="items">
        <tr>
          <td
            v-for="(positive, index) in items"
            :key="index + 'positive-metric'"
          >
            <span v-if="positive && positive.metricId">
              <editable-cell
                @change="changeItem($event, positive)"
                class="small-cell"
                type="number"
                :defaultValue="Number(positive.defaultValue)"
                :maxlength="14"
                :min="0"
                :number-key-press-validation="brandKeyPressValidation"
                :value="Number(positive.value)"
              />
            </span>
          </td>
        </tr>
      </template>
      <template v-slot:item.neutral="items">
        <tr>
          <td v-for="(neutral, index) in items" :key="index + 'neutral-metric'">
            <span v-if="neutral && neutral.metricId">
              <editable-cell
                @change="changeItem($event, neutral)"
                class="small-cell"
                type="number"
                :defaultValue="Number(neutral.defaultValue)"
                :maxlength="14"
                :min="0"
                :number-key-press-validation="brandKeyPressValidation"
                :value="Number(neutral.value)"
              />
            </span>
          </td>
        </tr>
      </template>
      <template v-slot:item.negative="items">
        <tr>
          <td
            v-for="(negative, index) in items"
            :key="index + 'negative-metric'"
          >
            <span v-if="negative && negative.metricId">
              <editable-cell
                @change="changeItem($event, negative)"
                class="small-cell"
                type="number"
                :defaultValue="negative.defaultValue"
                :maxlength="14"
                :min="0"
                :number-key-press-validation="brandKeyPressValidation"
                :value="negative.value"
              />
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-col class="reset-button">
      <slot></slot>
    </v-col>
  </div>
</template>

<script>
import EditableCell from "@/components/EditableCell";
import { canBeDigitsOnly } from "@/validators";
import { mapGetters } from "vuex";
export default {
  name: "SentimentTable",
  components: { EditableCell },
  props: {
    isChanged: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    changeItem: {
      type: Function,
      required: true
    },
    metricChanges: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data() {
    return {
      sentimentBrandsHeader: [
        { text: "Brands", value: "brandName", class: "brand-td" },
        {
          text: "Positive Post",
          value: "positive",
          class: "sentiment-td success--text"
        },
        { text: "Neutral Post", value: "neutral", class: "sentiment-td" },
        {
          text: "Negative Post",
          value: "negative",
          class: "sentiment-td error--text"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      metricsPreview: "metrics/getMetricsPreview"
    }),
    brands() {
      return this.metricsPreview.brands.reduce((acc, val) => {
        acc[val._id] = { brandName: val.name, brandId: val._id };
        return acc;
      }, {});
    },
    sentimentDataChangedValues() {
      const SENTIMENT_MAP = {
        "Positive Posts": "positive",
        "Neutral Posts": "neutral",
        "Negative Posts": "negative"
      };

      return this.metricsPreview.metrics.reduce((acc, val) => {
        for (let v of val.values) {
          acc[v.brandId][SENTIMENT_MAP[val.name]] = {
            brandId: v.brandId,
            metricId: v.metricId,
            value: this.metricChanges[v.metricId]
              ? this.metricChanges[v.metricId].value
              : v.value,
            defaultValue:
              this.metricChanges[v.metricId]?.defaultValue || v.value,
            sentiment: SENTIMENT_MAP[val.name]
          };
        }
        return acc;
      }, this.brands);
    },
    metricsData() {
      return this.isLoading
        ? []
        : Object.values(this.sentimentDataChangedValues);
    }
  },
  methods: {
    brandKeyPressValidation(event, input) {
      return canBeDigitsOnly(event, input);
    }
  }
};
</script>
