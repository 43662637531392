<template>
  <div class="input-text-center">
    <v-tooltip bottom :disabled="isTooltipDisabled || !showValueTooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          @change="handleChange"
          @keyup="handleKeyUp"
          v-model="input"
          ref="input"
          :defaultValue="defaultValue"
          :label="computedLabel"
          :maxlength="maxlength"
          v-bind="attrs"
          v-on="on"
          :counter="counter"
          :error="error"
          :rules="rules"
          :title="value"
          :value="input"
          :validate-on-blur="validateOnBlur"
        />
      </template>
      <span>{{ input }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { roundValue } from "@/helpers/helpers";
import { mapGetters } from "vuex";
import { isValueNaN } from "@/validators";

export default {
  name: "EditableCell",
  props: {
    value: {
      default: null
    },
    defaultValue: {
      default: null
    },
    type: {
      type: String,
      default: "text"
    },
    label: {
      type: [String, Number],
      default: ""
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    maxlength: {
      type: Number
    },
    minValueLengthToShowTooltip: {
      type: Number,
      default: 25
    },
    counter: {
      type: Number
    },
    rules: {
      type: Array,
      default: () => []
    },
    validateOnBlur: {
      type: Boolean,
      default: true
    },
    showValueTooltip: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    preventNumberValidation: {
      type: Boolean,
      default: false
    },
    preventTextValidation: {
      type: Boolean,
      default: false
    },
    /**
     * @returns {boolean}
     */
    numberKeyPressValidation: {
      type: Function,
      default: () => {
        return true;
      }
    }
  },
  data() {
    return {
      input: this.value
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser"
    }),
    computedLabel() {
      const label = this.label
        ? this.label.toLocaleString(this.user.country)
        : this.label;
      const value = this.value
        ? this.value.toLocaleString(this.user.country)
        : this.value;
      const input = this.input
        ? this.input.toLocaleString(this.user.country)
        : this.input;
      if (label.length > 0) {
        return label + "";
      }

      const defaultValue = this.defaultValue;

      if (defaultValue) {
        return this.value !== this.defaultValue
          ? this.defaultValue.toLocaleString(this.user.country)
          : "";
      }

      if (value === input) {
        return "";
      }
      return value ? value + "" : "";
    },
    isTooltipDisabled() {
      if (this.input === null) {
        return true;
      }
      return this.input.length < this.minValueLengthToShowTooltip;
    }
  },
  watch: {
    value() {
      this.input = this.value;
    }
  },
  methods: {
    handleChange(event) {
      if (this.type === "number" && !this.preventNumberValidation) {
        if (!this.validateNumberChange()) {
          return;
        }
      }
      if (this.type === "text" && !this.preventTextValidation) {
        if (!this.validateTextChange(event)) {
          return;
        }
      }
      this.$emit("change", this.input);
    },
    validateNumberChange() {
      let isValid = true;
      const roundedValue = roundValue(this.input, 2);

      if (isValueNaN(roundedValue)) {
        this.input = this.value;
        return false;
      }

      this.input = +roundedValue;

      if (this.min && !this.validateMin(this.input)) {
        isValid = false;
      }
      if (this.max && !this.validateMax(this.input)) {
        isValid = false;
      }
      return isValid;
    },
    validateTextChange(input) {
      this.input = input.replace(/\s\s+/g, " ").trim();
      return true;
    },
    validateMin(input) {
      if (roundValue(input, 2) < this.min) {
        this.$emit("change", this.value);
        this.input = this.value;
        return false;
      }

      return true;
    },
    validateMax(input) {
      if (roundValue(input, 2) > this.max) {
        this.$emit("change", this.value);
        this.input = this.value;
        return false;
      }

      return true;
    },
    handleKeyUp(event) {
      if (this.type === "number" && !this.preventNumberValidation) {
        if (!this.numberValidation(event)) {
          event.preventDefault();
          return;
        }
      }
      this.$emit("keyup", event);
    },
    numberValidation(event) {
      if (!this.preventNumberValidation) {
        return this.numberKeyPressValidation(event, this.input);
      }
      return true;
    }
  }
};
</script>

<style lang="scss">
.input-text-center {
  input {
    text-align: center;
  }
}
</style>
