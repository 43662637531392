<template>
  <div class="table-border pa-3">
    <div class="text-h6 title text-left">
      {{ $t("DATA_PREVIEW.TEXT.BAV.HEADER") }}
    </div>
    <div class="table-description text-left caption font-italic opacity-7">
      <span v-if="isChanged">
        <v-icon small> mdi-alert </v-icon>
        {{ $t("DATA_PREVIEW.TEXT.BAV.DESCRIPTION") }}
      </span>
    </div>
    <v-data-table
      :headers="bavMetricsBrandsHeader"
      :items="pillarsData"
      :items-per-page="pillarsData.length"
      :loading="isLoading"
      class="elevation-0 mt-4"
      disable-sort
      hide-default-footer
    >
      <template v-slot:item.brandName="items">
        <tr class="brands-td">
          <td v-for="brand in items" v-bind:key="brand.brandId">
            <div :title="brand.brandName" v-if="brand.brandId">
              {{ brand.brandName }}
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:item.vitality="items">
        <tr class="bav-metrics-td">
          <td
            v-for="(vitality, index) in items"
            v-bind:key="index + 'vitality'"
          >
            <span v-if="vitality && vitality.id">
              {{ displayValues(vitality) }}
            </span>
          </td>
        </tr>
      </template>
      <template v-slot:item.involvement="items">
        <tr class="bav-metrics-td">
          <td
            v-for="(involvement, index) in items"
            v-bind:key="index + 'involvement'"
          >
            <span v-if="involvement && involvement.id">
              {{ displayValues(involvement) }}
            </span>
          </td>
        </tr>
      </template>
      <template v-slot:item.mood="items">
        <tr class="bav-metrics-td">
          <td v-for="(mood, index) in items" v-bind:key="index + 'mood'">
            <span v-if="mood && mood.id">
              {{ displayValues(mood) }}
            </span>
          </td>
        </tr>
      </template>
      <template v-slot:item.prominence="items">
        <tr class="bav-metrics-td">
          <td
            v-for="(prominence, index) in items"
            v-bind:key="index + 'prominence'"
          >
            <span v-if="prominence && prominence.id">
              {{ displayValues(prominence) }}
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-col class="recalculate-button">
      <slot></slot>
    </v-col>
  </div>
</template>

<script>
import { localize, roundValue } from "@/helpers/helpers";
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "MetricsTable",
  props: {
    isChanged: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bavMetricsBrandsHeader: [
        { text: "Brands", value: "brandName", class: "brand-td" },
        { text: "Vitality", value: "vitality", class: "sentiment-td" },
        { text: "Involvement", value: "involvement", class: "sentiment-td" },
        { text: "Mood", value: "mood", class: "sentiment-td" },
        { text: "Prominence", value: "prominence", class: "sentiment-td" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      metricsPreview: "metrics/getMetricsPreview"
    }),
    language() {
      return this.user ? this.user.language : "en";
    },
    brands() {
      return this.metricsPreview.brands.reduce((acc, val) => {
        acc[val._id] = { brandName: val.name, brandId: val._id };
        return acc;
      }, {});
    },
    bavMetricsDataValues() {
      return this.metricsPreview.pillars.reduce((acc, val) => {
        for (let v of val.values) {
          acc[v.brandId][val.name.toLowerCase()] = {
            brandId: v.brandId,
            value: v.value,
            metricId: v.metricId,
            pillar: val.name.toLowerCase(),
            id: uuidv4()
          };
        }
        return acc;
      }, this.brands);
    },
    pillarsData() {
      return this.isLoading ? [] : Object.values(this.bavMetricsDataValues);
    }
  },
  methods: {
    displayValues(metric) {
      return metric.value
        ? localize(roundValue(metric.value, 2), this.language)
        : 0;
    }
  }
};
</script>
