<template>
  <v-container class="mt-16 data-preview white text-center">
    <div class="text-h6 title">
      {{ $t("DATA_PREVIEW.TEXT.HEADER") }}
    </div>
    <data-preview-tables />
  </v-container>
</template>

<script>
import DataPreviewTables from "@/views/DataPreview/components/DataPreviewTables";
export default {
  name: "Index",
  components: { DataPreviewTables }
};
</script>

<style lang="scss">
.data-preview {
  max-width: 1280px;
}

.small-cell {
  .v-text-field__details {
    display: none;
  }
  .v-text-field input {
    padding: 0 0 10px 0;
  }
  .v-input__slot {
    margin-bottom: 0;
  }
}
.brand-td {
  align-items: center;
  background-color: #3e485c;
  color: white !important;
  display: flex;
  justify-content: center;
}
.sentiment-td {
  span {
    display: block;
    text-align: center;
  }
}
.brands-td {
  background-color: #3e485c;
  display: flex;
  justify-content: center;
  margin: 0 -16px;
  text-align: center !important;
}
.brands-td div {
  color: white;
  max-width: 150px;
  overflow: hidden;
  padding: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.bav-metrics-td {
  display: flex;
  font-size: 16px;
  justify-content: center;
}
.table-border {
  border: 2px solid #c8cfdd;
  border-radius: 8px;
}
.reset-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}
.recalculate-button {
  display: flex;
  justify-content: flex-start;
  margin-top: 8px;
}
.table-description {
  min-height: 42px;
}
</style>
